import { ROUTE_TYPE } from '.'

export enum ADMIN_PATHS {
  BASE = '/',
  COMPANY = 'company',
  CALLS = 'calls',
  JOURNEYS = 'journeys',
  SEARCH = 'search',
  CHALLENGES = 'challenges',
  CHAT = 'chat',
  USER_MANAGEMENT = 'user-management',
}

enum ADMIN_APPS {
  LAYOUT = '@thriveglobal/thrive-admin-layout',
  COMPANY = '@thriveglobal/thrive-admin-company',
  CALLS = '@thriveglobal/thrive-admin-calls',
  SEARCH = '@thriveglobal/thrive-admin-search',
  JOURNEYS = '@thriveglobal/thrive-admin-journeys',
  AUTH_CORE = '@thriveglobal/thrive-web-auth-core',
  CHALLENGES = '@thriveglobal/thrive-admin-challenges',
  CHAT = '@thriveglobal/thrive-admin-chat',
  USER_MANAGEMENT = '@thriveglobal/thrive-admin-user-management',
}

export const getAdminRoutes = async (config) => {
  config.routes.push({
    type: ROUTE_TYPE.ROUTE,
    default: true,
    routes: [
      { type: ROUTE_TYPE.APP, name: ADMIN_APPS.COMPANY },
      { type: ROUTE_TYPE.APP, name: ADMIN_APPS.LAYOUT },
    ],
  })
  config.routes.push({
    type: ROUTE_TYPE.ROUTE,
    path: ADMIN_PATHS.CALLS,
    routes: [
      { type: ROUTE_TYPE.APP, name: ADMIN_APPS.CALLS },
      { type: ROUTE_TYPE.APP, name: ADMIN_APPS.LAYOUT },
    ],
  })
  config.routes.push({
    type: ROUTE_TYPE.ROUTE,
    path: ADMIN_PATHS.JOURNEYS,
    routes: [
      { type: ROUTE_TYPE.APP, name: ADMIN_APPS.JOURNEYS },
      { type: ROUTE_TYPE.APP, name: ADMIN_APPS.LAYOUT },
    ],
  })
  config.routes.push({
    type: ROUTE_TYPE.ROUTE,
    path: ADMIN_PATHS.SEARCH,
    routes: [
      { type: ROUTE_TYPE.APP, name: ADMIN_APPS.SEARCH },
      { type: ROUTE_TYPE.APP, name: ADMIN_APPS.LAYOUT },
    ],
  })
  config.routes.push({
    type: ROUTE_TYPE.ROUTE,
    path: ADMIN_PATHS.CHALLENGES,
    routes: [
      { type: ROUTE_TYPE.APP, name: ADMIN_APPS.CHALLENGES },
      { type: ROUTE_TYPE.APP, name: ADMIN_APPS.LAYOUT },
    ],
  })
  config.routes.push({
    type: ROUTE_TYPE.ROUTE,
    path: ADMIN_PATHS.CHAT,
    routes: [
      { type: ROUTE_TYPE.APP, name: ADMIN_APPS.CHAT },
      { type: ROUTE_TYPE.APP, name: ADMIN_APPS.LAYOUT },
    ],
  })
  config.routes.push({
    type: ROUTE_TYPE.ROUTE,
    path: ADMIN_PATHS.USER_MANAGEMENT,
    routes: [
      { type: ROUTE_TYPE.APP, name: ADMIN_APPS.USER_MANAGEMENT },
      { type: ROUTE_TYPE.APP, name: ADMIN_APPS.LAYOUT },
    ],
  })
}
