import { constructRoutes } from 'single-spa-layout'
import { InputRoutesConfigObject } from 'single-spa-layout/dist/types/isomorphic/constructRoutes'
import { beforeRouting } from './beforeRouting'
import { isAdmin } from '../Env'
import { getWebRoutes, getTroRoutes } from './webRoutes'
import { getAdminRoutes } from './adminRoutes'

const config: InputRoutesConfigObject = {
  mode: 'history',
  base: '/',
  disableWarnings: false,
  routes: [],
}

export enum ROUTE_TYPE {
  ROUTE = 'route',
  APP = 'application',
}

export type GetRoutesProps = {
  isThriveResetOnly: boolean
}

function getRoutes({ isThriveResetOnly = false }: GetRoutesProps) {
  if (isAdmin) {
    getAdminRoutes(config)
    return constructRoutes(config)
  }

  if (isThriveResetOnly) {
    getTroRoutes(config)
  } else {
    getWebRoutes(config)
  }

  window.addEventListener(
    'single-spa:before-routing-event',
    beforeRouting({ isThriveResetOnly }) as unknown as EventListener
  )

  return constructRoutes(config)
}

export default getRoutes
