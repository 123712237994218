import { registerApplication, start, addErrorHandler } from 'single-spa'
import { constructApplications, constructLayoutEngine } from 'single-spa-layout'

import { captureError } from './utils/datadog'
import { captureSentryError } from './utils/sentry'
import appErrorHandler from './utils/appErrorHandler'
import setupWindowGlobal from './utils/windowGlobal'
import getRoutes from './routing'
import { setupConfigCat } from './utils/configCat'

import crashScreen from './screens/crash-screen.html'

setupConfigCat()

async function main() {
  setupWindowGlobal()

  Promise.all([
    System.import('@thriveglobal/thrive-web-core'),
    System.import('@thriveglobal/thrive-web-auth-core')
  ])
  .then(async ([core, authCore]) => {
    await authCore.maybeRefreshToken()

    const authState = core.store.getState().auth
    const isThriveResetOnly = authCore.hasTroLicense(authState)
    const routes = getRoutes({ isThriveResetOnly })

    const applications = constructApplications({
      routes,
      loadApp: ({ name }) => System.import(name)
    })

    const layoutEngine = constructLayoutEngine({ routes, applications })

    applications.forEach(registerApplication)
    addErrorHandler(appErrorHandler(layoutEngine))

    layoutEngine.activate()
    start()
  }).catch(error => {
    console.error('Error loading thrive-web-root:', error)
    captureError(error)
    captureSentryError(error)
    document.body.innerHTML = crashScreen
  })
}
main()
